import React, { useEffect, useState } from 'react';
import client from '../contentfulClient';
import BlogPost from './BlogPost';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'react-router-dom';

const BlogPostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1220px;
  margin: 0 auto;
`;

const BlogPostContainer = styled.div`
  flex: 1 1 calc(50% - 20px); /* Two posts per row with some margin */
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%; /* Stack on small screens */
    margin: 10px 0; /* Adjust margin for stacked layout */
  }
`;

const BlogPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client.getEntries({ content_type: 'blogpost' })
      .then((response) => {
        setPosts(response.items);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="container">
      <BlogPostsWrapper>
        {posts.map((post) => (
          <BlogPostLazyLoad key={post.sys.id} post={post} />
        ))}
      </BlogPostsWrapper>
    </div>
  );
};

const BlogPostLazyLoad = ({ post }) => {
  const { ref, inView } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  return (
    <BlogPostContainer ref={ref} className={inView ? 'visible' : ''}>
      {inView && (
        <Link to={`/market-updates/${post.sys.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <BlogPost
            title={post.fields.title}
            summary={documentToReactComponents(post.fields.summary)}
            headImage={post.fields.headImage?.fields?.file?.url} // Adjust the path to your image field if necessary
          />
        </Link>
      )}
    </BlogPostContainer>
  );
};

export default BlogPosts;
