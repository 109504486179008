import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const OuterWrapper = styled.div`
  width: 100%;
  background-color: #EFF5F3;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const Container = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
  @media (max-width: 800px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const Card = styled.div`
  background: #307d5e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  width: 100%;
  max-width: 500px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;

  @media (max-width: 800px) {
    padding: 10px;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Title = styled.div`
  padding-bottom: 15px;
  font-size: 1em;
  color: white;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

const Price = styled.div`
  color: white;
  font-size: 2em;
  font-weight: bold;
`;

const Change = styled.div`
  color: white;
  font-size: 18px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChangeText = styled.span`
  display: flex;
  align-items: center;
`;

const ChangeValue = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props) => (props.positive ? '#D5F2E8' : '#C37878')};
`;

const ArrowUpRight = styled(FaArrowUp)`
  transform: rotate(45deg);
`;

const ArrowDownRight = styled(FaArrowDown)`
  transform: rotate(-45deg);
`;

const KpiCard = ({ selectedModel }) => {
  const [averagePrice, setAveragePrice] = useState(0);
  const [priceChange, setPriceChange] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://flask-backend-rp1a.onrender.com/api/kpi?searchTerm=${selectedModel}`);
        const data = await response.json();

        setAveragePrice(data.averagePrice);
        setPriceChange(data.priceChange);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [selectedModel]);

  return (
    <OuterWrapper>
      <Container>
        <Card ref={ref} className={inView ? 'visible' : ''}>
          <Title>AVERAGE PRICE</Title>
          <Price>€ {Math.round(averagePrice).toLocaleString()}</Price>
          <Change>
            <ChangeText>
              <ChangeValue>{priceChange.toFixed(0)}%</ChangeValue>
              compared to last year
            </ChangeText>
            <Box positive={priceChange > 0}>
              {priceChange > 0 ? (
                <ArrowUpRight color="#307D5E" />
              ) : priceChange < 0 ? (
                <ArrowDownRight color="#991B1C" />
              ) : null}
            </Box>
          </Change>
        </Card>
      </Container>
    </OuterWrapper>
  );
};

export default KpiCard;
