import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifyError = (message) => {
  toast.error(message, {
    position: "top-left", // Use string values directly
  });
};

const Toast = () => {
  return <ToastContainer />;
};

export default Toast;
