import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaStar } from 'react-icons/fa';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  max-width: 1220px;
  padding: 10px;  
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
  font-family: 'Arial, sans-serif';
`;

const PoweredByWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  opacity: 0;
  animation: ${props => props.isVisible ? fadeIn : ''} 1s forwards;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 2em;
  color: #292430;
  margin-right: 10px;
  text-align: left; 
`;

const PoweredBy = styled.span`
  font-size: 1em;
  padding: 5px 10px;
  border-radius: 8px;
  background: linear-gradient(45deg, #068B56, #206444);
  color: #fff;
  display: flex;
  align-items: center;
`;

const DescriptionBox = styled.div`
  font-size: 1em;
  line-height: 1.6;
  color: black;
  text-align: left;
  padding: 20px;
  background: #EFF5F3;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingCircle = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #206444;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${rotate} 1s linear infinite;
`;

const Placeholder = styled.p`
  font-size: 1em;
  line-height: 1.6;
  color: gray;
  text-align: center;
`;

const WatchDescription = ({ selectedModel }) => {
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const poweredByRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = poweredByRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedModel) {
      setLoading(true);
      // Fetch price development data
      fetch(`https://flask-backend-rp1a.onrender.com/api/price_development?searchTerm=${selectedModel}`)
        .then(response => response.json())
        .then(priceData => {
          console.log('Price data:', priceData);
          // Call GPT-3.5 Turbo API to generate the description
          fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer sk-proj-VBveE7MSzArVU1LzUgKcT3BlbkFJOSf8aa5RCFOLcn4vQ0hw`, // Use your actual API key
            },
            body: JSON.stringify({
              model: 'gpt-3.5-turbo',
              messages: [
                { role: 'system', content: 'You are a helpful assistant.' },
                {
                  role: 'user',
                  content: `Write a description for the watch model: ${selectedModel}. Include information about its price developments. 
                            The current average price is ${priceData.averagePrice}. The price change over the last year is ${priceData.priceChange}%. 
                            Ensure the response ends with a full sentence, within plus or minus 10 words of 200, and does not include any currency symbols.`
                },
              ],
              max_tokens: 250, // Request a bit more than 200 words to ensure complete sentences
            }),
          })
            .then(response => response.json())
            .then(data => {
              console.log('GPT-3.5 Turbo API response:', data); // Log the entire response for debugging
              if (data.choices && data.choices.length > 0) {
                let content = data.choices[0].message.content.trim();
                // Ensure the response ends with a full sentence within the word limit
                content = ensureFullSentence(content, 200, 10);
                setDescription(content);
              } else {
                setDescription('No description available.');
              }
              setLoading(false);
            })
            .catch(error => {
              console.error('Error fetching description:', error);
              setDescription('Error fetching description.');
              setLoading(false);
            });
        })
        .catch(error => {
          console.error('Error fetching price development:', error);
          setDescription('Error fetching price development.');
          setLoading(false);
        });
    }
  }, [selectedModel]);

  const ensureFullSentence = (text, wordLimit, wordVariance) => {
    const words = text.split(' ');
    if (words.length <= wordLimit + wordVariance && words.length >= wordLimit - wordVariance) {
      return text;
    }
    let truncatedText = words.slice(0, wordLimit + wordVariance).join(' ');
    if (!truncatedText.endsWith('.')) {
      truncatedText = truncatedText.substring(0, truncatedText.lastIndexOf('.')) + '.';
    }
    return truncatedText;
  };

  return (
    <Container>
      <PoweredByWrapper ref={poweredByRef} isVisible={isVisible}>
        <PoweredBy>
          <FaStar style={{ marginRight: '5px' }} /> Powered by GenAI
        </PoweredBy>
      </PoweredByWrapper>
      <TitleWrapper>
        <Title>About {selectedModel ? selectedModel : 'your selected watch'}</Title>
      </TitleWrapper>
      <DescriptionBox>
        {!selectedModel ? (
          <Placeholder>Please select a watch first</Placeholder>
        ) : loading ? (
          <LoadingCircle />
        ) : (
          <p>{description}</p>
        )}
      </DescriptionBox>
    </Container>
  );
};

export default WatchDescription;
