import React from 'react';
import styled from 'styled-components';
import LogoWhite from './Images/LogoWhite.svg';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const FooterWrapper = styled.div`
  width: 100%;
  background-color: #0F2319;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const FooterContainer = styled.div`
  max-width: 1220px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #0F2319;
  padding: 20px;
  height: 80vh;
  
  @media (max-width: 800px) {
    flex-direction: column;
    height: auto;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const Logo = styled.img`
  width: 300px;
  height: auto;
  margin-bottom: 10px;
  
  @media (max-width: 800px) {
    padding-bottom: 10px;
  }
`;

const LogoText = styled.div`
  color: white;
  font-size: 1.3em;
  text-align: left;
  margin-right: 30px;
  color: #EFF5F3;
  
  @media (max-width: 800px) {
    margin-top: 10px;
    margin-bottom: 40px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33%;
  
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 60px;
    text-align: center;
  }
`;

const SectionHeader = styled.h3`
  color: #EFF5F3;
  font-size: 2em;
  margin-bottom: 10px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuItem = styled.div`
  margin: 5px 0;
  cursor: pointer;
  font-size: 1.5em;
  color: white;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SocialIcon = styled.a`
  color: white;
  margin: 0 10px;
  font-size: 2em;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ExtendedFooter = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDashboardClick = () => {
    handleNavigation('/dashboard');
  };

  const handleMarketUpdatesClick = () => {
    handleNavigation('/market-updates');
  };

  const handleAboutUsClick = () => {
    handleNavigation('/about-us');
  };

  return (
    <FooterWrapper>
      <FooterContainer>
        <LogoSection>
          <Logo src={LogoWhite} alt="Logo" />
          <LogoText>Reliable watch valuations and market insights.</LogoText>
        </LogoSection>
        <Section>
          <SectionHeader>Resources</SectionHeader>
          <Menu>
            <MenuItem onClick={handleDashboardClick}>Dashboard</MenuItem>
            <MenuItem onClick={handleMarketUpdatesClick}>Market Updates</MenuItem>
            <MenuItem onClick={handleAboutUsClick}>About Us</MenuItem>
          </Menu>
        </Section>
        <Section>
          <SectionHeader>Socials</SectionHeader>
          <Socials>
            <SocialIcon href="https://facebook.com" target="_blank"><FaFacebook /></SocialIcon>
            <SocialIcon href="https://twitter.com" target="_blank"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank"><FaInstagram /></SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank"><FaLinkedin /></SocialIcon>
          </Socials>
        </Section>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default ExtendedFooter;
