import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import Toast, { notifyError } from './Toast'; // Import the Toast component and notifyError function
import '../App.css';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState(''); // State for the search input value
  const [suggestions, setSuggestions] = useState([]); // State for storing fetched suggestions
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // State for filtered suggestions based on input
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0); // State for the active suggestion index
  const [recentSearch, setRecentSearch] = useState(localStorage.getItem('recentSearch') || ''); // State for the most recent search term
  const suggestionsListRef = useRef(null); // Ref for the suggestions list
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation(); // Hook for getting current location

  useEffect(() => {
    // Fetch data from API
    fetch('https://flask-backend-rp1a.onrender.com/api/suggestions')
      .then(response => response.json()) // Convert response to JSON
      .then(data => {
        setSuggestions(data); // Set suggestions state with model names
      })
      .catch(error => console.error('Error fetching data:', error)); // Handle any errors
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      setRecentSearch(''); // Reset recent search term
      localStorage.removeItem('recentSearch'); // Clear recent search term from localStorage
    }
  }, [location.pathname]);

  const handleInputChange = (event) => {
    const input = event.target.value; // Get input value
    setSearchTerm(input); // Set searchTerm state with input value

    if (input === '') {
      setFilteredSuggestions([]); // Clear filtered suggestions if input is empty
    } else {
      // Filter suggestions based on input
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().startsWith(input.toLowerCase())
      );
      setFilteredSuggestions(filtered); // Set filtered suggestions
    }
    setActiveSuggestionIndex(0); // Reset active suggestion index
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion); // Set search term to clicked suggestion
    setFilteredSuggestions([]); // Clear filtered suggestions
  };

  const handleSearchClick = () => {
    // Check if search term is a valid suggestion
    if (suggestions.includes(searchTerm)) {
      if (onSearch) {
        onSearch(searchTerm); // Call onSearch prop with search term
      }
      setRecentSearch(searchTerm); // Update recent search term
      localStorage.setItem('recentSearch', searchTerm); // Store recent search term in localStorage
      if (location.pathname === '/') {
        navigate(`/dashboard?search=${searchTerm}`); // Navigate to dashboard with search term
      }
    } else {
      notifyError('Watch not available, please select another watch'); // Show error if search term is invalid
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      // Move down in the suggestions list
      if (activeSuggestionIndex < filteredSuggestions.length - 1) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1); // Increment active suggestion index
      }
    } else if (event.key === 'ArrowUp') {
      // Move up in the suggestions list
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1); // Decrement active suggestion index
      }
    } else if (event.key === 'Enter') {
      // Select suggestion on Enter key
      if (filteredSuggestions.length > 0) {
        setSearchTerm(filteredSuggestions[activeSuggestionIndex]); // Set search term to active suggestion
        setFilteredSuggestions([]); // Clear filtered suggestions
        handleSearchClick(); // Trigger search
      }
    } else if (event.key === 'Escape') {
      setFilteredSuggestions([]); // Clear filtered suggestions on Escape key
    }
  };

  const getPlaceholder = () => {
    if (location.pathname === '/dashboard' && !recentSearch) {
      return "Full watch market";
    }
    return recentSearch || "Search watch model";
  };

  return (
    <SearchBarWrapper>
      <div className="container">
        <StyledSearchBar>
          <StyledInput
            type="text"
            placeholder={getPlaceholder()}
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <StyledIcon onClick={handleSearchClick}>
            <FaSearch />
          </StyledIcon>
        </StyledSearchBar>
        {filteredSuggestions.length > 0 && (
          <SuggestionsList ref={suggestionsListRef}>
            {filteredSuggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                active={index === activeSuggestionIndex}
              >
                {suggestion}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
      </div>
      <Toast /> {/* Add the Toast component here */}
    </SearchBarWrapper>
  );
};

// Styled Components
const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  padding-top: 10px;
  position: relative; /* Needed to position the suggestions dropdown */
  background-color: white;
`;

const StyledSearchBar = styled.div`
  background-color: #F0EEF5;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1220px; /* Ensure the search bar does not exceed max width */
  border: 1px solid #F0EEF5; // Set the border size
  background-image: linear-gradient(#EFF5F3, #EFF5F3);
  background-origin: border-box;
  background-clip: padding-box, border-box; // Ensure the gradient is only on the border
`;

const StyledInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  padding: 8px;
  color: #757C79;
  font-size: 16px;

  &::placeholder {
    color: #757C79;
  }

  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled.button`
  background-color: #206444; /* Green background */
  border: none;
  padding: 7px;
  margin-left: 8px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  border-radius: 4px; /* Optional: rounded corners */
  transition: background-color 0.2s ease; /* Smooth transition for background color change */

  &:hover {
    background-color: #9FCFBF; /* Lighter green on hover */
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 60px; /* Adjust based on your input height */
  width: 100%;
  max-width: 1220px; /* Ensure the suggestions list does not exceed max width */
  background-color: white; /* Light background color */
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10; /* Ensure it appears above other elements */
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#EFF5F3' : 'transparent')}; /* Highlight active suggestion */
  &:hover {
    background-color: #EFF5F3; /* Lighter background on hover */
  }
`;

export default SearchBar;
