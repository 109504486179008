import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import logo from './Images/Logo.svg';

const Nav = styled.nav`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? '0' : '-250px')};
  width: 250px;
  height: 100vh;
  transition: right 0.3s ease-in-out;
  z-index: 10;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const Logo = styled.img`
  height: 40px;
  cursor: pointer;
`;

const CloseButton = styled.div`
  cursor: pointer;
  color: #222222;
  font-size: 1.2em;
  font-weight: 400;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #e0e0e0;
`;

const Ul = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  margin: 8px 0;
  height: 50px;
  cursor: pointer;
  transition: color 0.3s ease;
  font-weight: 600;
  font-size: 1em;
  color: #222222;
  &.active {
    color: #206444;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const MobileMenu = ({ open, toggleMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (path) => {
    navigate(path);
    toggleMenu();
  };

  return (
    <Nav open={open}>
      <Header>
        <Logo src={logo} onClick={() => navigate('/')} />
        <CloseButton onClick={toggleMenu}>
          <FaTimes />
        </CloseButton>
      </Header>
      <Divider />
      <Ul>
        <Li className={location.pathname === '/dashboard' ? 'active' : ''} onClick={() => handleMenuClick('/dashboard')}>
          Dashboard
        </Li>
        <Li className={location.pathname === '/market-updates' ? 'active' : ''} onClick={() => handleMenuClick('/market-updates')}>
          Market Updates
        </Li>
        <Li className={location.pathname === '/collection' ? 'active' : ''} onClick={() => handleMenuClick('/collection')}>
        Collection
        </Li>
        <Li className={location.pathname === '/about-us' ? 'active' : ''} onClick={() => handleMenuClick('/about-us')}>
          About Us
        </Li>
      </Ul>
    </Nav>
  );
};

export default MobileMenu;
