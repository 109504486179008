import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import client from '../contentfulClient';
import styled from 'styled-components';
import BlogPost from './BlogPost';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PostWrapper = styled.div`
  width: 100%;
  padding: 10px;
  max-width: 1220px;
  padding-top: 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
  margin-bottom: 40px;
`;

const PostTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #292430;
`;

const PostContent = styled.div`
  font-size: 1em;
  line-height: 1.8;
  font-family: 'Arial', sans-serif;
  color: #4A4A4A;
`;

const HeadImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const OtherPostsWrapper = styled.div`
  width: 100%;
  max-width: 1220px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
`;

const BlogPostContainer = styled.div`
  flex: 1 1 calc(50% - 20px);
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    margin: 10px 0;
  }
`;

const BlogPostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState([]);

  useEffect(() => {
    client.getEntry(id)
      .then((response) => {
        setPost(response);
      })
      .catch(console.error);

    client.getEntries({ content_type: 'blogpost' })
      .then((response) => {
        setOtherPosts(response.items.filter(item => item.sys.id !== id));
      })
      .catch(console.error);
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <PostWrapper>
        {post.fields.headImage && (
          <HeadImage src={post.fields.headImage.fields.file.url} alt={post.fields.title} />
        )}
        <PostTitle>{post.fields.title}</PostTitle>
        <PostContent>{documentToReactComponents(post.fields.content)}</PostContent>
      </PostWrapper>

      <h3>Other Posts</h3>
      <OtherPostsWrapper>
        {otherPosts.map((otherPost) => (
          <BlogPostContainer key={otherPost.sys.id}>
            <Link to={`/market-updates/${otherPost.sys.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <BlogPost
                title={otherPost.fields.title}
                summary={documentToReactComponents(otherPost.fields.summary)}
                headImage={otherPost.fields.headImage?.fields?.file?.url}
              />
            </Link>
          </BlogPostContainer>
        ))}
      </OtherPostsWrapper>
    </Container>
  );
};

export default BlogPostDetail;
