import React from 'react';
import styled from 'styled-components';

const WatchList = ({ watches }) => {
  return (
    <WatchListWrapper>
      {watches.map((watch, index) => (
        <WatchItem key={index}>
          <WatchName>{watch.name}</WatchName>
          <WatchDetails>
            <Detail>
              <strong>Average Price:</strong> €{watch.averagePrice}
            </Detail>
            <Detail>
              <strong>Price Change:</strong> {watch.priceChange}%
            </Detail>
          </WatchDetails>
        </WatchItem>
      ))}
    </WatchListWrapper>
  );
};

const WatchListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const WatchItem = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 1220px;
`;

const WatchName = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

const WatchDetails = styled.div`
  margin-top: 10px;
`;

const Detail = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;

export default WatchList;
