// MarketUpdates.js
import React from 'react';
import { useParams } from 'react-router-dom';
import HeaderComponent from './HeaderComponent';
import BlogPosts from './BlogPosts';
import BlogPostDetail from './BlogPostDetail';
import Footer from './Footer';
import ExtendedFooter from './ExtendedFooter';

const MarketUpdates = () => {
  const { id } = useParams();

  return (
    <div>
      <HeaderComponent />
        {id ? <BlogPostDetail /> : <BlogPosts />}
    <ExtendedFooter />
    <Footer />
    </div>
  );
};

export default MarketUpdates;
