import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import 'chart.js/auto';
import { format } from 'date-fns';

const BackgroundContainer = styled.div`
  background-color: #EFF5F3;
  width: 100%;
  padding: 10px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const Title = styled.div`
  padding-bottom: 20px;
  font-size: 1em;
  font-weight: bold;
  color: #757C79;
  width: 100%;
  text-align: left;
`;

const LineChartFiltered = ({ searchTerm, secondSearchTerm, width, height }) => {
  const [firstChartData, setFirstChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [secondChartData, setSecondChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [fullMarketData, setFullMarketData] = useState({
    labels: [],
    datasets: [],
  });

  const fetchData = (searchTerm, setData, color) => {
    fetch(`https://flask-backend-rp1a.onrender.com/api/data?searchTerms=${encodeURIComponent(searchTerm)}`)
      .then(response => response.json())
      .then(data => {
        const dates = data.map(item => format(new Date(item.Date_collected), 'dd-MM-yyyy'));
        const averagePrices = data.map(item => item.Price);

        setData({
          labels: dates,
          datasets: [
            {
              label: searchTerm || 'N/A',
              data: averagePrices,
              fill: true,
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                  return null;
                }
                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, color === '#F5CB8B' ? 'rgba(245, 203, 139, 0.5)' : 'rgba(213, 242, 232, 1)');
                gradient.addColorStop(1, color === '#F5CB8B' ? 'rgba(245, 203, 139, 0)' : 'rgba(159, 207, 191, 0)');
                return gradient;
              },
              borderColor: color,
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: color,
            },
          ],
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchFullMarketData = () => {
    fetch('https://flask-backend-rp1a.onrender.com/api/data/full')
      .then(response => response.json())
      .then(data => {
        const dates = data.map(item => format(new Date(item.Date_collected), 'dd-MM-yyyy'));
        const averagePrices = data.map(item => item.Price);

        setFullMarketData({
          labels: dates,
          datasets: [
            {
              label: 'Average price',
              data: averagePrices,
              fill: true,
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                  return null;
                }
                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, 'rgba(213, 242, 232, 1)');
                gradient.addColorStop(1, 'rgba(159, 207, 191, 0)');
                return gradient;
              },
              borderColor: '#068B56',
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: '#068B56',
            },
          ],
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const mergeDataSets = (firstData, secondData) => {
    const allDates = Array.from(new Set([...firstData.labels, ...secondData.labels])).sort((a, b) => {
      const dateA = new Date(a.split('-').reverse().join('-'));
      const dateB = new Date(b.split('-').reverse().join('-'));
      return dateA - dateB;
    });

    const mapDataToDates = (data, dates) => {
      const dataMap = new Map(data.labels.map((label, index) => [label, data.datasets[0].data[index]]));
      return dates.map(date => dataMap.get(date) || null);
    };

    const firstDataMapped = mapDataToDates(firstData, allDates);
    const secondDataMapped = mapDataToDates(secondData, allDates);

    return {
      labels: allDates,
      datasets: [
        firstData.datasets[0] && firstData.datasets[0].data.length > 0
          ? { ...firstData.datasets[0], data: firstDataMapped }
          : {},
        secondData.datasets[0] && secondData.datasets[0].data.length > 0
          ? { ...secondData.datasets[0], data: secondDataMapped }
          : {},
      ].filter(dataset => dataset.label), // Ensure no undefined datasets are included
    };
  };

  useEffect(() => {
    if (searchTerm) {
      fetchData(searchTerm, setFirstChartData, '#068B56');
    }
  }, [searchTerm]);

  useEffect(() => {
    if (secondSearchTerm) {
      fetchData(secondSearchTerm, setSecondChartData, '#F5CB8B');
    }
  }, [secondSearchTerm]);

  useEffect(() => {
    if (!searchTerm && !secondSearchTerm) {
      fetchFullMarketData();
    }
  }, [searchTerm, secondSearchTerm]);

  const numberFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = '';
            if (context.parsed.y !== null) {
              label += numberFormatter.format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return numberFormatter.format(value);
          },
        },
      },
    },
  };

  return (
    <BackgroundContainer>
      <ChartContainer>
        <Title>AVERAGE PRICE TREND</Title>
        <div style={{ position: 'relative', height: height || '70vh', width: width || '100%' }}>
          <Line
            data={
              searchTerm || secondSearchTerm
                ? mergeDataSets(firstChartData, secondChartData)
                : fullMarketData
            }
            options={options}
          />
        </div>
      </ChartContainer>
    </BackgroundContainer>
  );
};

export default LineChartFiltered;
