import React, { useState } from 'react';
import SearchBar from './SearchCollection';
import WatchList from './WatchList';
import HeaderComponent from './HeaderComponent'; // Import the HeaderComponent
import Toast, { notifyError } from './Toast'; // Import notifyError from Toast
import styled from 'styled-components';

const Collection = () => {
  const [watches, setWatches] = useState([]);

  const handleAddWatch = (name, averagePrice, priceChange) => {
    if (!watches.some(watch => watch.name === name)) {
      setWatches([...watches, { name, averagePrice, priceChange }]);
    } else {
      notifyError('This watch is already in the collection');
    }
  };

  const totalValue = watches.reduce((total, watch) => total + watch.averagePrice, 0);

  return (
    <div className="App">
      <HeaderComponent />
      <SearchBar onAddWatch={handleAddWatch} />
      <TotalValueRectangle>
        Total Collection Value: €{Math.round(totalValue)}
      </TotalValueRectangle>
      <WatchList watches={watches} />
      <Toast />
    </div>
  );
};

const TotalValueRectangle = styled.div`
  background-color: #EFF5F3;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 20px auto;
  width: 100%;
  max-width: 1220px;
  text-align: center;
  font-size: 18px;
  color: #333;
`;

export default Collection;
