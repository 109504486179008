import styled from 'styled-components';
import '../App.css'; // Import the App.css file

const HeaderWrapper = styled.div`
    background-color: #307D5E; 
    color: white;
    margin: 0; /* Ensure there is no margin */
    padding: 0; /* Ensure there is no padding */
    display: flex;
    justify-content: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    align-items: center; /* Vertically center the content */
    height: 7vh;
    width: 100%;
    @media (max-width: 800px) {
        padding: 0 10px;
        height: 7vh;
    }
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    color: white;
    max-height: 1vh;
    h1 {
        background: transparent;
        font-size: 1em;
        font-weight: 400;
        margin: 0; /* Ensure no extra margin */
        @media (max-width: 800px) {
            padding: 0 10px;
            font-size: 0.9em;
        }
        }
    }

`;


function NotificationHeader() {
  return (
    <HeaderWrapper>
        <Header>
            <h1>Beta of market insights live. New features following soon</h1>
        </Header>
    </HeaderWrapper>
  );
}

export default NotificationHeader;
