import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SecondSearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    fetch('https://flask-backend-rp1a.onrender.com/api/suggestions')
      .then(response => response.json())
      .then(data => setSuggestions(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleInputChange = (event) => {
    const input = event.target.value;
    setSearchTerm(input);

    if (input === '') {
      setFilteredSuggestions([]);
    } else {
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().startsWith(input.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }
    setActiveSuggestionIndex(0);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setFilteredSuggestions([]);
  };

  const handleSearchClick = () => {
    onSearch(searchTerm);
    setFilteredSuggestions([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' && activeSuggestionIndex < filteredSuggestions.length - 1) {
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    } else if (event.key === 'ArrowUp' && activeSuggestionIndex > 0) {
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (event.key === 'Enter' && filteredSuggestions.length > 0) {
      handleSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
    } else if (event.key === 'Escape') {
      setFilteredSuggestions([]);
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <SearchBarWrapper>
      <Container>
        {isExpanded && (
          <StyledSearchBar>
            <StyledInput
              type="text"
              placeholder="Search another watch model"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <StyledButton onClick={handleSearchClick}>
              COMPARE
            </StyledButton>
          </StyledSearchBar>
        )}
        <ToggleButton onClick={handleToggle}>
          {isExpanded ? 'Close' : '+ Compare to another watch'}
        </ToggleButton>
        {filteredSuggestions.length > 0 && (
          <SuggestionsList>
            {filteredSuggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                active={index === activeSuggestionIndex}
              >
                {suggestion}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
      </Container>
    </SearchBarWrapper>
  );
};

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: white;
  padding: 5px;
  padding-bottom: 15px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1220px;
  justify-content: flex-start;
  flex-direction: row;
`;

const StyledSearchBar = styled.div`
  background-color: #F0EEF5;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  border: 1px solid #F0EEF5;
  background-image: linear-gradient(#EFF5F3, #EFF5F3);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  margin-right: 10px;
`;

const StyledInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  padding: 8px;
  color: #757C79;
  font-size: 16px;

  &::placeholder {
    color: #757C79;
  }

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  background-color: #F5CB8B;
  border: none;
  padding: 7px;
  margin-left: 8px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #FAE5C5;
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 60px;
  width: 100%;
  max-width: 1220px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#EFF5F3' : 'transparent')};
  &:hover {
    background-color: #EFF5F3;
  }
`;

const ToggleButton = styled.button`
  background-color: #FBECD5;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  color: #757C79;
  font-size: 16px;
  border-radius: 25px;
  transition: background-color 0.2s ease;
  white-space: nowrap;

  &:hover {
    background-color: #d4d9d6;
  }
`;

export default SecondSearchBar;
