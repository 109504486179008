// Home.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationHeader from './components/Notification.js';
import HeaderComponent from './components/HeaderComponent';
import MainMessage from './components/MainMessage';
import SearchBar from './components/SearchBar';
import BlogPosts from './components/BlogPosts';
import Footer from './components/Footer';
import WatchBrands from './components/WatchBrands.js';
import LineChartHeaderMain from './components/LineChartHeaderMain';
import LineChartFullMarket from './components/LineChartFullMarket';
import KPIcards from './components/KPIcardsSlider.js'; 
import ExtendedFooter from './components/ExtendedFooter.js';
import USPs from './components/USPs.js';

const Home = ({ setSearchTerm }) => {
  const navigate = useNavigate();

  const handleSearch = (term) => {
    navigate(`/dashboard?search=${term}`);
  };

  useEffect(() => {
    // Clear the search term when the home component is mounted
    setSearchTerm('');
  }, [setSearchTerm]);

  return (
    <>
      <NotificationHeader />
      <HeaderComponent />
      <MainMessage />
      <SearchBar onSearch={handleSearch} />
      <KPIcards /> {/* Use the new KPIcards component */}
      <USPs />
      <LineChartHeaderMain title="Average price trend" subtitle="Full market, since 2022" />
      <LineChartFullMarket width={800} height={400} />
      <BlogPosts />
      <WatchBrands />
      <ExtendedFooter />
      <Footer />
    </>
  );
};

export default Home;
