import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import Toast, { notifyError } from './Toast';
import '../App.css';

const SearchCollection = ({ onAddWatch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const suggestionsListRef = useRef(null);

  useEffect(() => {
    fetch('https://flask-backend-rp1a.onrender.com/api/suggestions')
      .then(response => response.json())
      .then(data => {
        setSuggestions(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleInputChange = (event) => {
    const input = event.target.value;
    setSearchTerm(input);

    if (input === '') {
      setFilteredSuggestions([]);
    } else {
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().startsWith(input.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }
    setActiveSuggestionIndex(0);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setFilteredSuggestions([]);
  };

  const handleSearchClick = () => {
    if (suggestions.includes(searchTerm)) {
      fetch(`https://flask-backend-rp1a.onrender.com/api/price_development?searchTerm=${searchTerm}`)
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            notifyError(data.error);
          } else {
            onAddWatch(searchTerm, data.averagePrice, data.priceChange);
          }
        })
        .catch(error => notifyError('Error fetching data: ' + error));
    } else {
      notifyError('Watch not available, please select another watch');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      if (activeSuggestionIndex < filteredSuggestions.length - 1) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (event.key === 'ArrowUp') {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (event.key === 'Enter') {
      if (filteredSuggestions.length > 0) {
        setSearchTerm(filteredSuggestions[activeSuggestionIndex]);
        setFilteredSuggestions([]);
      }
    } else if (event.key === 'Escape') {
      setFilteredSuggestions([]);
    }
  };

  return (
    <SearchBarWrapper>
      <div className="container">
        <StyledSearchBar>
          <StyledInput
            type="text"
            placeholder="Search watch model"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <StyledIcon onClick={handleSearchClick}>
            <FaSearch />
          </StyledIcon>
        </StyledSearchBar>
        {filteredSuggestions.length > 0 && (
          <SuggestionsList ref={suggestionsListRef}>
            {filteredSuggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                active={index === activeSuggestionIndex}
              >
                {suggestion}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
      </div>
      <Toast />
    </SearchBarWrapper>
  );
};

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  padding-top: 10px;
  position: relative;
  background-color: white;
`;

const StyledSearchBar = styled.div`
  background-color: #F0EEF5;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1220px;
  border: 1px solid #F0EEF5;
  background-image: linear-gradient(#EFF5F3, #EFF5F3);
  background-origin: border-box;
  background-clip: padding-box, border-box;
`;

const StyledInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  padding: 8px;
  color: #757C79;
  font-size: 16px;

  &::placeholder {
    color: #757C79;
  }

  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled.button`
  background-color: #206444;
  border: none;
  padding: 7px;
  margin-left: 8px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #9FCFBF;
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 60px;
  width: 100%;
  max-width: 1220px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#EFF5F3' : 'transparent')};

  &:hover {
    background-color: #EFF5F3;
  }
`;

export default SearchCollection;
