import styled, { keyframes } from 'styled-components';
import '../App.css'; // Import the App.css file
import '../index.css'; // Import the index.css file
import design from './Images/Design.svg';

// Keyframes for fadeIn animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledMain = styled.main`
  width: 40%;
  max-width: 1220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-weight: 300;
  line-height: 1;
  font-size: 1.4em;


  h1 {
    color: white;
    line-height: 1;
    letter-spacing: 1px;
    opacity: 0;
    font-weight: 300;
    animation: ${fadeIn} 2s ease-in-out forwards;
  }

  h2 {
    background: none;
    color: white;
    font-size: 0.8em;
    line-height: 1.55;
    font-weight: 400;
    margin-top: 20px;
    opacity: 0;
    animation: ${fadeIn} 2s ease-in-out forwards;
  }

  @media (max-width: 800px) {
    width: 100%;
    min-height: 0;
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 1.3em;

    h1 {
      color: white;
      line-height: 1;
      opacity: 0;
      font-weight: 300;
      animation: ${fadeIn} 2s ease-in-out forwards;
    }
  
    h2 {
      background: none;
      color: white;
      font-size: 0.8em;
      line-height: 1.55;
      font-weight: 400;
      margin-top: 20px;
      opacity: 0;
      animation: ${fadeIn} 2s ease-in-out forwards;
    }
  }
`;

const StyledImage = styled.img`
  width: 45%;
  max-width: 1220px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 60px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: -1000;
  background-color: #216443;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

function MainMessage() {
  return (
    <Container>
      <InnerContainer>
        <StyledMain>
          <h1>Watch valuations and market insights</h1>
          <h2>Hangtag provides reliable watch valuations, tools to track inventory value and market insights for smarter and easier deals</h2>
        </StyledMain>
        <StyledImage src={design} alt="Design" />
      </InnerContainer>
    </Container>
  );
}

export default MainMessage;
