import React, { useState } from 'react';
import styled from 'styled-components';
import logo from './Images/Logo.svg';
import { FaBars } from 'react-icons/fa';
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import MobileMenu from './MobileMenu';

const HeaderWrapper = styled.div`
  background-color: #216443;
  margin: 0;
  padding: 0;
  display: flex;
  height: 9vh;
  min-height: 60px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1); /* Adjusted shadow */
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  position: relative;
  z-index: 1000; /* Ensure it's above other elements */
`;


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1220px;
`;

const Nav = styled.nav`
  background-color: #216443;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
  ul {
    list-style-type: none;
    display: flex;
  }
  li {
    display: flex;
    align-items: center;
    margin-left: 20px;
    height: 5vh;
    min-height: 60px;
    cursor: pointer;
    transition: color 0.3s ease;
    border: none;
    font-weight: 600;
    font-size: 0.9em;
    color: white;
    height: 100%;
    position: relative;
    border-bottom: 3px solid transparent;
    &.active {
      border-bottom: 3px solid #9FCFBF;
    }
  }
  li:hover {
    opacity: 0.8;
  }
  li .text {
    position: relative;
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 8px;
  }
`;

const ClickableHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-weight: 500;
  h1 {
    background: transparent;
    font-size: 1.5em;
    padding: 0 20px;
    margin-left: -15px;
  }
`;

const Image = styled.img`
  height: 2em;
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  color: white;
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
  }
  svg {
    font-size: 1.5em;
  }
`;

function HeaderComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderWrapper>
      <Container>
        <ClickableHeader onClick={handleLogoClick}>
          <Image src={logo}></Image>
          <Header>
            <h1>Hangtag</h1>
          </Header>
        </ClickableHeader>
        <Nav>
          <ul>
            <li className={`dashboard ${location.pathname === '/dashboard' ? 'active' : ''}`} onClick={() => navigate('/dashboard')}>
              <div className="text">Dashboard</div>
            </li>
            <li className={location.pathname === '/market-updates' ? 'active' : ''} onClick={() => navigate('/market-updates')}>
              <div className="text">Market Updates</div>
            </li>
            <li className={location.pathname === '/collection' ? 'active' : ''} onClick={() => navigate('/collection')}>
            <div className="text">Collection</div>
            </li>
            <li className={location.pathname === '/about-us' ? 'active' : ''} onClick={() => navigate('/about-us')}>
              <div className="text">About Us</div>
            </li>
          </ul>
        </Nav>
        <Hamburger onClick={toggleMenu}>
          <FaBars />
        </Hamburger>
        <MobileMenu open={menuOpen} toggleMenu={toggleMenu} />
      </Container>
    </HeaderWrapper>
  );
}

export default HeaderComponent;
