import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LineChartFiltered from './LineChartFiltered';
import KPIcard from './KPIcard';
import WatchDescription from './WatchDescription';
import SearchBar from './SearchBar';
import HeaderComponent from './HeaderComponent';
import Footer from './Footer';
import ExtendedFooter from './ExtendedFooter';
import SecondSearchBar from './SecondSearchBar';

const Dashboard = ({ searchTerm, setSearchTerm }) => {
  const [searchParams] = useSearchParams();
  const initialSearchTerm = searchParams.get('search');
  const [secondSearchTerm, setSecondSearchTerm] = useState('');

  useEffect(() => {
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
    }
  }, [initialSearchTerm, setSearchTerm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSecondSearch = (term) => {
    setSecondSearchTerm(term);
  };

  return (
    <div>
      <HeaderComponent />
      <SearchBar onSearch={setSearchTerm} />
      <SecondSearchBar onSearch={handleSecondSearch} />
      <KPIcard selectedModel={searchTerm} />
      <LineChartFiltered searchTerm={searchTerm} secondSearchTerm={secondSearchTerm} />
      <WatchDescription selectedModel={searchTerm} />
      <ExtendedFooter />
      <Footer />
    </div>
  );
};

export default Dashboard;
