import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Dashboard from './components/Dashboard';
import Collection from './components/Collection';
import MarketUpdates from './components/MarketUpdates'; // Import the new component

function App() {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home setSearchTerm={setSearchTerm} />} />
          <Route path="/dashboard" element={<Dashboard searchTerm={searchTerm} setSearchTerm={setSearchTerm} />} />
          <Route path="/market-updates/:id" element={<MarketUpdates />} /> {/* Update this route */}
          <Route path="/market-updates" element={<MarketUpdates />} /> {/* Update this route */}
          <Route path="/collection" element={<Collection />} /> {/* Update this route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
