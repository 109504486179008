import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30%);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const KPIContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 10px;
  max-width: 1220px;
  background-color: white;
  margin: 0 auto;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1220px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:after {
    left: auto;
    right: 0;
  }

  @media (max-width: 768px) {
    -webkit-overflow-scrolling: touch;
  }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  animation: ${scroll} 100s linear infinite;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 120px;
  height: 60px;
  margin: 8px;
  padding: 10px;
  background-color: white;
  border: 1px solid #F0EEF5;
  border-radius: 5px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const BrandName = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #4B3E5D;
  text-align: left;
  width: 100%;
`;

const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ChangeContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const Price = styled.div`
  font-size: 12px;
  color: #7D7D7D;
`;

const Change = styled.div`
  font-size: 12px;
  color: ${props => (props.change >= 0 ? 'green' : 'red')};
  text-align: right;
  white-space: nowrap;
`;

const KPIcards = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://flask-backend-rp1a.onrender.com/api/kpi/brands'); //http://localhost:5000/api/kpi/brands
      const data = await response.json();
      setData(data);
    };

    fetchData();
  }, []);

  return (
    <KPIContainer>
      <ScrollingWrapper>
        {data.map((item) => (
          <Card key={item.brand}>
            <BrandName>{item.brand}</BrandName>
            <ValuesContainer>
              <Price>€{Math.round(item.averagePrice).toLocaleString()}</Price>
              <ChangeContainer>
                <Change change={item.priceChange}>{item.priceChange.toFixed(2)}%</Change>
              </ChangeContainer>
            </ValuesContainer>
          </Card>
        ))}
        {data.map((item) => (
          <Card key={`${item.brand}-duplicate`}>
            <BrandName>{item.brand}</BrandName>
            <ValuesContainer>
              <Price>€{Math.round(item.averagePrice).toLocaleString()}</Price>
              <ChangeContainer>
                <Change change={item.priceChange}>{item.priceChange.toFixed(2)}%</Change>
              </ChangeContainer>
            </ValuesContainer>
          </Card>
        ))}
      </ScrollingWrapper>
    </KPIContainer>
  );
};

export default KPIcards;