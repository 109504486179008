import React from 'react';
import styled from 'styled-components';

const PostWrapper = styled.div`
  width: 100%;
  padding: 0;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02);
  border: 1px solid #e0dcdc;
  border-radius: 8px;  /* Small rounded corner */
  box-sizing: border-box;
  overflow: hidden; /* Ensure rounded corners apply to children */
`;

const PostTitle = styled.h2`
  font-size: 2em;
  margin: 20px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #222222;
`;

const PostSummary = styled.div`
  font-size: 0.95em;
  line-height: 1.5;
  font-family: 'Arial', sans-serif;
  color: #4A4A4A;
  margin: 0 20px 20px 20px;
`;

const HeadImage = styled.img`
  width: 100%;
  display: block; /* Remove inline element space */
  height: auto;
  border-top-left-radius: 8px; /* Ensure image respects rounded corners */
  border-top-right-radius: 8px; /* Ensure image respects rounded corners */
`;

const BlogPost = ({ title, summary, headImage }) => (
  <PostWrapper>
    {headImage && <HeadImage src={headImage} alt={title} />}
    <PostTitle>{title}</PostTitle>
    <PostSummary>{summary}</PostSummary>
  </PostWrapper>
);

export default BlogPost;
