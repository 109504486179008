import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import EyeIcon from './Images/EyeIcon.png';
import WatchIcon from './Images/WatchIcon.png';
import ChartIcon from './Images/ChartIcon.png';

const Container = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px 10px;
`;

const Title = styled.h1`
  text-align: left;
  margin-bottom: 40px;
  color: #222222;
`;

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  gap: 20px;
`;

const Box = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  background-color: #EFF5F3;
  border: 1px solid #EFF5F3;
  border-radius: 8px;
  padding: 20px 10px;
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  opacity: 0;
  transform: translateY(20px);

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const IconContainer = styled.div`
  width: 50px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Content = styled.div`
  text-align: left;
`;

const TitleText = styled.h2`
  margin-bottom: 10px;
  font-weight: 300;
  color: #222222;
  line-height: 1.4;
`;

const Paragraph = styled.p`
  margin-top: 10px;
  color: #4A4A4A;
  line-height: 1.6;
`;

const USPComponent = () => {
  return (
    <Container>
      <Title>Our value proposition</Title>
      <BoxContainer>
        <USPBox
          icon={EyeIcon}
          title="Reliable and transparent"
          description="Our platform is built on a foundation of reliability and transparency. We provide accurate and trustworthy watch valuations through a methodologically rigorous approach, ensuring you can make informed decisions with confidence."
        />
        <USPBox
          icon={WatchIcon}
          title="Tailored insights"
          description="Get insights that matter to you. Our valuations and market insights are customized at the brand, model, and reference number level, delivering precise information to meet your specific requirements."
        />
        <USPBox
          icon={ChartIcon}
          title="Delivered fit for purpose"
          description="Whether you prefer a comprehensive dashboard or a concise valuation scorecard, we deliver data in the format that best suits your business needs, making it easy for you to access and utilize the information effectively."
        />
      </BoxContainer>
    </Container>
  );
};

const USPBox = ({ icon, title, description }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <Box ref={ref} className={inView ? 'visible' : ''}>
      <IconContainer>
        <img src={icon} alt={`${title} Icon`} />
      </IconContainer>
      <Content>
        <TitleText>{title}</TitleText>
        <Paragraph>{description}</Paragraph>
      </Content>
    </Box>
  );
};

export default USPComponent;
