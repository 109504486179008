import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import 'chart.js/auto';
import { format } from 'date-fns';

const BackgroundContainer = styled.div`
  background-color: #EFF5F3;
  width: 100%;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffff; 
`;

const LineChartFullMarket = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Average price',
        data: [],
        fill: true,
        backgroundColor: '#9FCFBF',
        borderColor: '#068B56',
        borderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: '#068B56',
      },
    ],
  });

  useEffect(() => {
    fetch('https://flask-backend-rp1a.onrender.com/api/data/full')  //http://localhost:5000
      .then(response => response.json())
      .then(data => {
        const dates = data.map(item => format(new Date(item.Date_collected), 'dd-MM-yyyy'));
        const averagePrices = data.map(item => item.Price);

        setChartData({
          labels: dates,
          datasets: [
            {
              label: 'Average price',
              data: averagePrices,
              fill: true,
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                  return null;
                }
                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, 'rgba(213, 242, 232, 1)');
                gradient.addColorStop(1, 'rgba(159, 207, 191, 0)');
                return gradient;
              },
              borderColor: '#068B56',
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: '#068B56',
            },
          ],
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const numberFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += numberFormatter.format(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return numberFormatter.format(value);
          }
        }
      },
      x: {
        grid: {
          display: false, // This line disables the vertical gridlines
        }
      }
    }
  };

  return (
    <BackgroundContainer>
      <ChartContainer>
        <div style={{ position: 'relative', height: '75vh' }}>
          <Line data={chartData} options={options} />
        </div>
      </ChartContainer>
    </BackgroundContainer>
  );
};

export default LineChartFullMarket;
