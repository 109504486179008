import styled from "styled-components";
import "../App.css"; // Importing the App.css file

const LineChartHeaderWrapper = styled.div`  
    background-color: #EFF5F3;
    width: 100%;
    padding-left: 10px;
`;

const Header = styled.header`
    display: flex;
    margin-top: 5vh;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    color: #222222;
    background-color: #EFF5F3 !important; /* Use !important */
    h1 {
        font-size: 2em;
        line-height: 1;
    }
    h2 {
        font-weight: 300;
        color: #757C79;
        margin-top: 10px;
        font-size: 1.1em;
    }
`;

function LineChartHeaderMain({ title, subtitle }) {
    return (
        <LineChartHeaderWrapper>
            <div className="container">
                <Header>
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                </Header>
            </div>
        </LineChartHeaderWrapper>
    );
}

export default LineChartHeaderMain;
