// src/components/FooterComponent.js
import React from 'react';
import styled from 'styled-components';

const FooterText = styled.footer`
  background-color: #0F2319;
  color: white;
  display: flex;
  font-size: 0.8em;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 100%;
`;

const Footer = () => {
  return (
    <FooterText>
      ©2024 Hantag Technology. All rights reserved.
    </FooterText>
  );
}

export default Footer;
