import React from 'react';
import styled, { keyframes } from 'styled-components';
import cartier from './Images/Cartier.png';
import rolex from './Images/Rolex.png';
import omega from './Images/Omega.png';
import patek from './Images/Patek.png';
import audemars from './Images/Audemars.png';
import vacheron from './Images/Vacheron.png';
import '../App.css'; // Import the App.css file

const slide = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); } /* Moves full width of SlideTrack */
`;

const FullWidthWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #EFF5F3;
  border-bottom: 2px solid #EFF5F3;
  background-color: #EFF5F3;
  padding-bottom: 10vh;
  @Media (max-width: 800px) {
    padding-bottom: 0vh;
  }
`;

const Container = styled.div`
  max-width: 1235px;
  padding: 5vh;

  margin: 0 auto;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  text-align: left;
  width: 100%;
`;

const Slider = styled.div`
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  overflow: hidden;
  display: flex;
  align-items: center; /* Center the logos vertically */
  @media (max-width: 800px) {
`;

const SlideTrack = styled.div`
  display: flex;
  width: 200%; /* Adjusted to make the track twice the width of the container */
  animation: ${slide} 60s linear infinite; /* Slowed down the animation */
`;

const Slide = styled.div`
  flex: 0 0 auto;
  margin-right: 15px; /* Set distance between slides */
  img {
    width: auto;
    height: 5vh; /* Ensures the images fit within 5vh height */
    object-fit: contain; /* Maintains the aspect ratio */
  }
`;

const Title = styled.h2`
  font-weight: 300;
  h1 {
    color: #222222;
    font-size: 1.5em;
    font-weight: 600;
  }
  h2 {
    font-size: 1em;
    font-weight: 300;
    color: #222222;
  }
`;

function WatchBrands() {
  return (
    <FullWidthWrapper>
      <Container>
      <Title><h1>Your brand portfolio</h1> 
      <h2>We've got it covered</h2></Title>
        <Slider>
          <SlideTrack>
            <Slide><img src={cartier} alt="Cartier" /></Slide>
            <Slide><img src={rolex} alt="Rolex" /></Slide>
            <Slide><img src={omega} alt="Omega" /></Slide>
            <Slide><img src={patek} alt="Patek Philippe" /></Slide>
            <Slide><img src={audemars} alt="Audemars Piguet" /></Slide>
            <Slide><img src={vacheron} alt="Vacheron Constantin" /></Slide>
            {/* Repeat the images to create a seamless loop */}
            <Slide><img src={cartier} alt="Cartier" /></Slide>
            <Slide><img src={rolex} alt="Rolex" /></Slide>
            <Slide><img src={omega} alt="Omega" /></Slide>
            <Slide><img src={patek} alt="Patek Philippe" /></Slide>
            <Slide><img src={audemars} alt="Audemars Piguet" /></Slide>
            <Slide><img src={vacheron} alt="Vacheron Constantin" /></Slide>
            <Slide><img src={cartier} alt="Cartier" /></Slide>
            <Slide><img src={rolex} alt="Rolex" /></Slide>
            <Slide><img src={omega} alt="Omega" /></Slide>
            <Slide><img src={patek} alt="Patek Philippe" /></Slide>
            <Slide><img src={audemars} alt="Audemars Piguet" /></Slide>
            <Slide><img src={vacheron} alt="Vacheron Constantin" /></Slide>
            <Slide><img src={cartier} alt="Cartier" /></Slide>
            <Slide><img src={rolex} alt="Rolex" /></Slide>
            <Slide><img src={omega} alt="Omega" /></Slide>
            <Slide><img src={patek} alt="Patek Philippe" /></Slide>
            <Slide><img src={audemars} alt="Audemars Piguet" /></Slide>
            <Slide><img src={vacheron} alt="Vacheron Constantin" /></Slide>
            <Slide><img src={cartier} alt="Cartier" /></Slide>
            <Slide><img src={rolex} alt="Rolex" /></Slide>
            <Slide><img src={omega} alt="Omega" /></Slide>
            <Slide><img src={patek} alt="Patek Philippe" /></Slide>
            <Slide><img src={audemars} alt="Audemars Piguet" /></Slide>
            <Slide><img src={vacheron} alt="Vacheron Constantin" /></Slide>
            <Slide><img src={cartier} alt="Cartier" /></Slide>
            <Slide><img src={rolex} alt="Rolex" /></Slide>
            <Slide><img src={omega} alt="Omega" /></Slide>
            <Slide><img src={patek} alt="Patek Philippe" /></Slide>
            <Slide><img src={audemars} alt="Audemars Piguet" /></Slide>
            <Slide><img src={vacheron} alt="Vacheron Constantin" /></Slide>
          </SlideTrack>
        </Slider>
      </Container>
    </FullWidthWrapper>
  );
}

export default WatchBrands;
